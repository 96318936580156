<template>
  <div>
    <!-- <pre> {{ playerOptionsFinal }}</pre> -->
    <!-- <vue-vimeo-player video-id="668796450?h=cb6e15d01d" video-url="https://player.vimeo.com/video/668796450?h=cb6e15d01d" v-if="isVimeo" /> -->
    <!-- v-else -->
    <video-player
      ref="videoPlayer"
      class="video-player-box"
      :options="playerOptionsFinal"
      :playsinline="true"
      @play="onPlayerPlay($event)"
      @playing="onPlayerPlaying($event)"
      @pause="onPlayerPause($event)"
      @ended="onPlayerEnded($event)"
      @ready="onPlayerReady"
      @loadeddata="onPlayerLoadedData($event)"
    />
    <b-progress :max="100" height="20px" class="my-1">
      <b-progress-bar 
        variant="success" 
        :value="percentViewed" 
        :label="`${percentViewed}%`" 
      />
    </b-progress>
    <!-- custom-event-name="customstatechangedeventname"
    @statechanged="playerStateChanged($event)" -->
    <!-- @waiting="onPlayerWaiting($event)"
    @playing="onPlayerPlaying($event)"
    @loadeddata="onPlayerLoadedData($event)"
    @timeupdate="onPlayerTimeupdate($event)"
    @canplay="onPlayerCanplay($event)"
    @canplaythrough="onPlayerCanplaythrough($event)" -->
  </div>
</template>

<script>
import 'video.js/dist/video-js.css';
import 'videojs-youtube';
// import 'videojs-vimeo';
// import '@devmobiliza/videojs-vimeo/dist/videojs-vimeo.cjs';
import { videoPlayer } from 'vue-video-player';
// import { vueVimeoPlayer } from 'vue-vimeo-player'

export default {
  name: 'VideoPlayerNew2',

  components: {
    videoPlayer,
    // vueVimeoPlayer
  },

  props: {
    sourceSrc: {
      type: String,
      required: true,
    },
    isYoutube: {
      type: Boolean,
      default: () => false,
    },
    isVimeo: {
      type: Boolean,
      default: () => false,
    },
    playerOptions: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      duration: 0,
      currentTime: 0,
      playerOptionsFinal: {
        preload: 'metadata',
        fluid: true,
        aspectRatio: "16:9",
        controls: true,
        techOrder: ['html5'],
        playbackRates: [0.75, 1.0, 1.5, 2.0],
        autoplay: true,
        sources: [
          {
            src: this.sourceSrc,
            type: 'video/mp4',
          },
        ],
      },
    };
  },

  computed: {
    percentViewed() {
      return ((this.currentTime * 100) / this.duration).toFixed(2) || 0;
    },
  },

  watch: {
    playerOptions: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.playerOptionsFinal = { ...this.playerOptionsFinal, ...newValue };
          if (this.isYoutube) {
            this.playerOptionsFinal.sources[0].type = 'video/youtube';
            this.playerOptionsFinal.techOrder = ['youtube'];
          }
          // if (this.isVimeo) {
          //   this.playerOptionsFinal.sources[0].type = 'video/vimeo';
          //   this.playerOptionsFinal.techOrder = ['vimeo'];
          // }
        }
      },
    },
  },

  methods: {
    // listen event
    onPlayerPlay(player) {
      console.log('player play!', player.duration());
      if (!this.duration) {
        this.duration = player.duration();
      }
    },
    onPlayerPlaying(player) {
      console.log('player playing!!!!', player);
    },
    onPlayerPause(player) {
      console.log('player paused', player);
      this.currentTime = player.currentTime();
    },
    onPlayerEnded(player) {
      console.log('player ended, complete video', player);
      this.currentTime = player.currentTime();
      if (player.ended && this.percentViewed > 99) {
        this.$emit('completed');
      }
      // alert('Finish Video!!!');
    },
    onPlayerLoadedData(player) {
      console.log('player onPlayerLoadedData!', player.duration());
      this.duration = player.duration();
    },
    // ...player event

    // player is ready
    onPlayerReady(player) {
      console.log('the player is ready', player);
      // you can use it to do something...
      // player.[methods]
    },

    // or listen state event
    // playerStateChanged(playerCurrentState) {
    //   console.log('player current update state', playerCurrentState);
    // },
    // onPlayerTimeUpdate(player) {
    //   console.log('player TIME update state', player);
    //   if (player.currentTime() === player.duration()) {
    //     console.log('video is ended');
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.video-player-box {
  ::v-deep .video-js {
    width: 100%;
    height: 100%;
    min-height: 25rem;
  }
}

</style>
